import { z } from 'zod';

import { Locale, POSSIBLE_LOCALES } from '@/lib/translation/types';

export enum Environment {
  local = 'local',
  ci = 'ci',
  prod = 'production',
  test = 'test',
}

export enum LoggerType {
  simple = 'simple',
  secured = 'secured',
}

export enum ClientLoggerType {
  console = 'console',
  prod = 'prod',
}

export enum LogLevel {
  debug = 'debug',
  info = 'info',
  warn = 'warn',
  error = 'error',
}

export enum NodeEnv {
  production = 'production',
  test = 'test',
  development = 'development',
}

const environment = z.nativeEnum(Environment);
const logLevel = z.nativeEnum(LogLevel);

const strictBooleanSchema = z.preprocess((value) => {
  const result = z
    .enum(['true', 'false'])
    .transform((enumValue) => JSON.parse(enumValue))
    .safeParse(value);
  return result.success ? result.data : value;
}, z.boolean());

const validLocalesSchema = z
  .string()
  .transform((val): unknown => {
    try {
      return JSON.parse(val);
    } catch {
      return z.NEVER;
    }
  })
  .pipe(
    z
      .array(
        z
          .string()
          .refine((str): str is Locale =>
            POSSIBLE_LOCALES.includes(str as Locale),
          ),
      )
      .min(1),
  );

const apiCoreEndpointStrategy = z.enum(['mock', 'sowefund']);

export const serverSideSchema = z.object({
  NODE_ENV: z.nativeEnum(NodeEnv),
  NEXT_PUBLIC_ENVIRONMENT: environment,
  NEXT_PUBLIC_LOG_LEVEL: logLevel,
  NEXT_PUBLIC_HOST: z.string().url(),
  API_CORE_CONFIG: z.object({
    preregister: apiCoreEndpointStrategy,
    getProjects: apiCoreEndpointStrategy,
    getProject: apiCoreEndpointStrategy,
    getTestimonials: apiCoreEndpointStrategy,
    getFooterSections: apiCoreEndpointStrategy,
    signIn: apiCoreEndpointStrategy,
    forgotPassword: apiCoreEndpointStrategy,
    signUp: apiCoreEndpointStrategy,
    resetPassword: apiCoreEndpointStrategy,
    me: apiCoreEndpointStrategy,
    logout: apiCoreEndpointStrategy,
    addProjectToFavorite: apiCoreEndpointStrategy,
    registerOptional: apiCoreEndpointStrategy,
    getIndustries: apiCoreEndpointStrategy,
    getProfileIndustries: apiCoreEndpointStrategy,
    postFavoriteIndustries: apiCoreEndpointStrategy,
    updateProfile: apiCoreEndpointStrategy,
    updateFinancialSituation: apiCoreEndpointStrategy,
    getFinancialSituation: apiCoreEndpointStrategy,
    getMaxInvestAmountAuthorized: apiCoreEndpointStrategy,
    getFolderStepOne: apiCoreEndpointStrategy,
    updateFolderStepOne: apiCoreEndpointStrategy,
    getFolderStepTwo: apiCoreEndpointStrategy,
    updateFolderStepTwo: apiCoreEndpointStrategy,
    getFolderStepTwoBis: apiCoreEndpointStrategy,
    updateFolderStepTwoBis: apiCoreEndpointStrategy,
    getFolderStepThree: apiCoreEndpointStrategy,
    updateFolderStepThree: apiCoreEndpointStrategy,
    getInvestmentDetails: apiCoreEndpointStrategy,
    getInvestmentVehicles: apiCoreEndpointStrategy,
    updateInvestmentAmount: apiCoreEndpointStrategy,
    updateInvestmentInvestmentVehicleId: apiCoreEndpointStrategy,
    updateInvestmentPaymentMethod: apiCoreEndpointStrategy,
    createOrRetrieveInvestmentId: apiCoreEndpointStrategy,
    getPaymentMethodsData: apiCoreEndpointStrategy,
    getPortfolio: apiCoreEndpointStrategy,
    updatePassword: apiCoreEndpointStrategy,
    getPaymentInformations: apiCoreEndpointStrategy,
    getNewsletters: apiCoreEndpointStrategy,
    updateNewsletters: apiCoreEndpointStrategy,
    getLegalEntity: apiCoreEndpointStrategy,
    getGeneralMeetings: apiCoreEndpointStrategy,
    getLegalEntityDocuments: apiCoreEndpointStrategy,
    getFavoriteProjects: apiCoreEndpointStrategy,
    getSocialAuthList: apiCoreEndpointStrategy,
    getSocialAuthRedirectUrl: apiCoreEndpointStrategy,
    postSocialAuthCode: apiCoreEndpointStrategy,
    getGeneralMeetingMinutes: apiCoreEndpointStrategy,
    getGeneralMeetingChairmanReportAndResolutions: apiCoreEndpointStrategy,
    getGeneralMeetingAnnualAccounts: apiCoreEndpointStrategy,
    getProfile: apiCoreEndpointStrategy,
    getLegalEntityShareholding: apiCoreEndpointStrategy,
    getShareOwnershipCertificate: apiCoreEndpointStrategy,
    getLegalEntityInvestmentVehicles: apiCoreEndpointStrategy,
    getTransactions: apiCoreEndpointStrategy,
    withdrawFromWallet: apiCoreEndpointStrategy,
    getBankTransferInformation: apiCoreEndpointStrategy,
    getProjectInvestments: apiCoreEndpointStrategy,
    getChequeInformation: apiCoreEndpointStrategy,
    createWalletSupplyTransaction: apiCoreEndpointStrategy,
    getWalletSupplyTransaction: apiCoreEndpointStrategy,
    getWalletSupplyPaymentLink: apiCoreEndpointStrategy,
    getVehicleExemptTaxCertificate: apiCoreEndpointStrategy,
    getInvestmentBill: apiCoreEndpointStrategy,
    getInvestmentSubscriptionReport: apiCoreEndpointStrategy,
    uploadRib: apiCoreEndpointStrategy,
    getRib: apiCoreEndpointStrategy,
    getProxySignature: apiCoreEndpointStrategy,
    uploadIdentityKYCDocuments: apiCoreEndpointStrategy,
    getIdentityKYCDocuments: apiCoreEndpointStrategy,
    getProxyToSignDocument: apiCoreEndpointStrategy,
    getSubscriptionLetterId: apiCoreEndpointStrategy,
    getDocument: apiCoreEndpointStrategy,
    confirmWallet: apiCoreEndpointStrategy,
    getProjectSlugFromId: apiCoreEndpointStrategy,
    applyPromoCode: apiCoreEndpointStrategy,
  }),
  API_CORE_BASE_URL: z.string().url(),
  NEXT_PUBLIC_INTERCOM_APP_ID: z.string(),
  NEXT_PUBLIC_GTM_ID: z.string(),
  NEXT_PUBLIC_GTM_PREVIEW: z.string(),
  NEXT_PUBLIC_GTM_AUTH: z.string(),
  NEXT_PUBLIC_GTM_ENABLED: strictBooleanSchema,
  LOGGER: z.nativeEnum(LoggerType),
  STORAGE_PATH: z.string(),
  SENTRY_ENABLED: strictBooleanSchema,
  SENTRY_DSN: z.string().optional(),
  NEXT_PUBLIC_IS_SOCIAL_LOGIN_ENABLED: strictBooleanSchema,
  NEXT_PUBLIC_IS_INDEXATION_ENABLED: strictBooleanSchema,
  NEXT_PUBLIC_ACTIVE_LANGUAGES: validLocalesSchema,
  PRISMIC_WEBHOOK_SECRET: z.string(),
});

export const clientSideSchema = z
  .object({
    NEXT_PUBLIC_ENVIRONMENT: environment,
    NEXT_PUBLIC_LOG_LEVEL: logLevel,
    NEXT_PUBLIC_HOST: z.string().url(),
    NEXT_PUBLIC_LOGGER: z.nativeEnum(ClientLoggerType),
    NEXT_PUBLIC_SENTRY_ENABLED: strictBooleanSchema,
    NEXT_PUBLIC_SENTRY_DSN: z.string().optional(),
    NEXT_PUBLIC_INTERCOM_APP_ID: z.string(),
    NEXT_PUBLIC_INTERCOM_ENABLED: strictBooleanSchema,
    NEXT_PUBLIC_RECAPTCHA_KEY: z.string(),
    NEXT_PUBLIC_IS_SIGNATURE_MOCK_ENABLED: strictBooleanSchema,
    NEXT_PUBLIC_UNIVERSIGN_BASE_URL: z.string(),
    NEXT_PUBLIC_UNIVERSIGN_TEST_URL: z.string().optional(),
    NEXT_PUBLIC_IS_SOCIAL_LOGIN_ENABLED: strictBooleanSchema,
    NEXT_PUBLIC_GTM_ID: z.string(),
    NEXT_PUBLIC_GTM_PREVIEW: z.string(),
    NEXT_PUBLIC_GTM_AUTH: z.string(),
    NEXT_PUBLIC_GTM_ENABLED: strictBooleanSchema,
    NEXT_PUBLIC_IS_INDEXATION_ENABLED: strictBooleanSchema,
    NEXT_PUBLIC_ACTIVE_LANGUAGES: validLocalesSchema,
  })
  .refine(
    (env) => {
      if (env.NEXT_PUBLIC_SENTRY_ENABLED && !env.NEXT_PUBLIC_SENTRY_DSN) {
        return false;
      }
      return true;
    },
    {
      message:
        'NEXT_PUBLIC_SENTRY_DSN should be defined if NEXT_PUBLIC_SENTRY_ENABLED is true',
      path: ['NEXT_PUBLIC_SENTRY_DSN'],
    },
  );
