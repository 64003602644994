import { clientSideSchema } from './types';

// Need to explicitely pass each public variable for bundle substitution
// https://nextjs.org/docs/app/building-your-application/configuring/environment-variables#bundling-environment-variables-for-the-browser
const parsedEnvVariables = clientSideSchema.safeParse({
  NEXT_PUBLIC_ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT,
  NEXT_PUBLIC_HOST: process.env.NEXT_PUBLIC_HOST,
  NEXT_PUBLIC_LOG_LEVEL: process.env.NEXT_PUBLIC_LOG_LEVEL,
  NEXT_PUBLIC_LOGGER: process.env.NEXT_PUBLIC_LOGGER,
  NEXT_PUBLIC_SENTRY_ENABLED: process.env.NEXT_PUBLIC_SENTRY_ENABLED,
  NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  NEXT_PUBLIC_INTERCOM_APP_ID: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
  NEXT_PUBLIC_RECAPTCHA_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_KEY,
  NEXT_PUBLIC_IS_SIGNATURE_MOCK_ENABLED:
    process.env.NEXT_PUBLIC_IS_SIGNATURE_MOCK_ENABLED,
  NEXT_PUBLIC_UNIVERSIGN_BASE_URL: process.env.NEXT_PUBLIC_UNIVERSIGN_BASE_URL,
  NEXT_PUBLIC_UNIVERSIGN_TEST_URL: process.env.NEXT_PUBLIC_UNIVERSIGN_TEST_URL,
  NEXT_PUBLIC_IS_SOCIAL_LOGIN_ENABLED:
    process.env.NEXT_PUBLIC_IS_SOCIAL_LOGIN_ENABLED,
  NEXT_PUBLIC_GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,
  NEXT_PUBLIC_GTM_PREVIEW: process.env.NEXT_PUBLIC_GTM_PREVIEW,
  NEXT_PUBLIC_GTM_AUTH: process.env.NEXT_PUBLIC_GTM_AUTH,
  NEXT_PUBLIC_GTM_ENABLED: process.env.NEXT_PUBLIC_GTM_ENABLED,
  NEXT_PUBLIC_INTERCOM_ENABLED: process.env.NEXT_PUBLIC_INTERCOM_ENABLED,
  NEXT_PUBLIC_IS_INDEXATION_ENABLED:
    process.env.NEXT_PUBLIC_IS_INDEXATION_ENABLED,
  NEXT_PUBLIC_ACTIVE_LANGUAGES: process.env.NEXT_PUBLIC_ACTIVE_LANGUAGES,
});

if (!parsedEnvVariables.success) {
  // This will be run during build time
  // eslint-disable-next-line no-console
  console.error({
    message: 'Invalid environment values',
    error: parsedEnvVariables.error,
  });
  throw new Error('Invalid environment values');
}

const env = parsedEnvVariables.data.NEXT_PUBLIC_ENVIRONMENT;

const clientConfig = {
  env,
  isDeployedEnv: ['production', 'test'].includes(env),
  activeLocales: parsedEnvVariables.data.NEXT_PUBLIC_ACTIVE_LANGUAGES,
  logger: {
    level: parsedEnvVariables.data.NEXT_PUBLIC_LOG_LEVEL,
    type: parsedEnvVariables.data.NEXT_PUBLIC_LOGGER,
  },
  host: parsedEnvVariables.data.NEXT_PUBLIC_HOST,
  sentry: {
    isEnabled: parsedEnvVariables.data.NEXT_PUBLIC_SENTRY_ENABLED,
    dsn: parsedEnvVariables.data.NEXT_PUBLIC_SENTRY_DSN,
    environment: parsedEnvVariables.data.NEXT_PUBLIC_ENVIRONMENT,
  },
  intercom: {
    enabled: parsedEnvVariables.data.NEXT_PUBLIC_INTERCOM_ENABLED,
    appId: parsedEnvVariables.data.NEXT_PUBLIC_INTERCOM_APP_ID,
  },
  recaptcha: {
    key: parsedEnvVariables.data.NEXT_PUBLIC_RECAPTCHA_KEY,
  },
  sowefundAddress: {
    name: 'Sowefund SAS',
    line: '19 rue du 4 Septembre',
    city: 'Paris',
    zipCode: '75002',
  },
  cgv: 'https://sowefund.com/cgu',
  signature: {
    isMockEnabled:
      parsedEnvVariables.data.NEXT_PUBLIC_IS_SIGNATURE_MOCK_ENABLED,
    universign: {
      baseUrl: parsedEnvVariables.data.NEXT_PUBLIC_UNIVERSIGN_BASE_URL,
      testUrl: parsedEnvVariables.data.NEXT_PUBLIC_UNIVERSIGN_TEST_URL,
    },
  },
  featureFlag: {
    isSocialLoginEnabled:
      parsedEnvVariables.data.NEXT_PUBLIC_IS_SOCIAL_LOGIN_ENABLED,
    isIndexationEnabled:
      parsedEnvVariables.data.NEXT_PUBLIC_IS_INDEXATION_ENABLED,
  },
  analytics: {
    enabled: parsedEnvVariables.data.NEXT_PUBLIC_GTM_ENABLED,
    gtm: {
      id: parsedEnvVariables.data.NEXT_PUBLIC_GTM_ID,
      preview: parsedEnvVariables.data.NEXT_PUBLIC_GTM_PREVIEW,
      auth: parsedEnvVariables.data.NEXT_PUBLIC_GTM_AUTH,
    },
  },
} as const;

export default clientConfig;
